.post_container {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;

    .style_dropdown {
        height: 50px;
        -webkit-box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.75);
    }

    .style_wrapper_image {
        display: flex;
        justify-content: center;
        align-items: center;

        .style_image_child {
            margin: 20px;
            border-radius: 10px;
        }
    }

    .style_title_form_input {
        font-size: 15px;
        color: gray;
        font-weight: 800;
    }

    .style_form_input {
        background-color: rgba(0, 0, 0, 0.1);
        font-size: medium;
        color: rgba(0, 0, 0, 1);
        height: 50px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.75);
        margin-bottom: 10px;        
    }

    .style_button_submit {
        margin-top: 20px;
        width:200px;
        height: 50px;
        background-color: rgba(0, 0, 0, 0.75);
        border-radius: 10px;
        font-size: small;
        color: #fff;
    }
}