.container-editor{
    margin-top: 20px;
    margin-left: 250px;
    margin-right: 250px;
    h3 {
        display: flex;
        justify-content: center;
    }
    .quill{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .button{
        display: flex;
        justify-content: center;
        button {
            width:200px;
            height: 50px;
            background-color: rgba(0, 0, 0, 0.75);
            border-radius: 10px;
            font-size: small;
            color: #fff;
        }
    }

}