.container-delete {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    background-color: #CFCBE4;
    margin-right: 400px;
    margin-left: 400px;
    padding: 100px 50px 100px 50px;
    border-radius: 7px;
    .content-delete{
        display: flex;  
        flex-direction: column;
        button {
            margin-bottom: 10px;
        }
    }
}