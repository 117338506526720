.LeftSidebar_TopSection {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
    height: 4em;
    background-color: #CFCBE4;
    -webkit-box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .style_wrapper_logo {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 1em;
        margin-top: 1em;
        cursor: pointer;

        .style_text_logo {
            margin-top: 0;
            font-size: 1em;
            color: #000;
        }
    }
}