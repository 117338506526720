.table-container {
    margin: 0 auto;
    // max-width: 50%;
    width: 60%;
    margin-top: 10%;
    margin-bottom: 10%;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.1);
}


.textfield {
    width: 200px;
    height: 32px;
    padding-right: 32px;
    padding-left: 50px; 
}
// h-[32px] w-[200px] pr-[32px] pl-[50px] text-sm border border-[#C2C9D1] rounded-md rounded-t-[5px] rounded-b-[5px]