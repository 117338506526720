.create-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 300px;
    margin-right: 300px;
    margin-top:100px;
    padding: 10px;
    .form-group {
        margin-top: 3px;
        margin-bottom: 8px;
        border: none;
        
        .form-control{
            margin-bottom: 10px;
            width: 100%;
            padding: 15px 20px;
            background-color: white;
            // border: 2px solid black;
            border-radius: 8px;
            
        }
    }
    .deleteBtn{
        margin-top: 10px;
      cursor: pointer;
      font-weight: 500;
      padding: 11px 28px;
      border-radius: 12px;
      font-size: 0.8rem;
      border: none;
      color: #fff;
      background: #CFCBE4;
      transition: all 0.25s ease;
    }
    .deleteBtn:hover {
      box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
      transform: translateY(-5px);
      background: #CFCBE4;
    }
}