.body-login {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    overflow: hidden;
    background-color:  #9587E4;
    .ipl-login-box {
        text-align: center;
        margin: 30px 10px;
        padding: 20px;
        width: 100%;
        max-width: 400px;
        background: #9587E4;
        border-radius: 35px;
        .login-form {
            text-align: left;
            padding: 20px;
            background-color: #9587E4;
            label {
                font-size: 17px;
                background-color: #9587E4;
              }
            .form-control {
                margin-top: 3px;
                margin-bottom: 8px;
                background-color: #9587E4;
                border: none;
                input {
                    width: 100%;
                    padding: 15px 20px;
                    background-color: white;
                    // border: 2px solid black;
                    border-radius: 15px;
                  }
                .button-login-masuk {
                    margin-top: 15px;
                    width: 100%;
                    padding: 15px 20px;
                    color: #000000;
                    font-size: 16px;
                    background-color: white;
                    border: none;
                    border-radius: 15px;
                    font-weight: bold;
                }
            }
          }
      }
  }