.Account_container {
   margin-top: 100px;


    
    .Table_container {
        margin: 0 auto;
        // max-width: 50%;
        width: 50%;
        margin-top: 10%;
        margin-bottom: 10%;
        border-radius: 10px;
        border: 1px solid rgba(0,0,0,0.1);
        .Button-create{
            display: flex;
            justify-content: flex-end;
            padding: 10px;
            button {
                border-radius: 5px;
                padding: 5px;
                border: 1px solid #CFCBE4;
                background-color: #CFCBE4;
                color: gray;
                font-weight: 700;
            }
            :hover{
                background-color: #CFCBE4;
                opacity: 80%;
            }
        }

        .Table {
            height: 10000px;
        }
    }
}