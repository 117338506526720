.Leftsidebar_container_overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    // background-color: black;
    transition: all ease 0.5s;
}

// .Leftsidebar_container_overlay--show {
//     opacity: 0.5;
//     visibility: visible;
// }

// .Leftsidebar_container_overlay--hide {
//     opacity: 0;
//     visibility: hidden;
// }