$left-sidebar-width: 15em;
$height-sub_menu: -5em;

.LeftSidebar_LeftSection {
    position: fixed;
    width: $left-sidebar-width;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.75);
    transition: all ease 0.5s;
    z-index: 10;

    .LeftSidebar_LeftSection_topWrapper {
        width: 100%;
        height: 4em;
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        align-items: center;
        background-color: #CFCBE4;
        border-bottom: 1px solid #0004;
        padding-right: 10px;
    }

    .LeftSidebar_LeftSection_menuWrapper {
        list-style-type: none;
        margin: 1em;
        padding: 0;

        li {
            margin-bottom: 1em;
            
            .parent_sub_menu {
                display: flex;
                flex-direction: row;
                align-items: center;

                .icon_chevron {
                    margin-left: 100px;
                }
                .icon_chevron:hover{
                    cursor: pointer;
                }
            }

            .child_sub_menu {
                transition: all ease 1s;
            }

            .child_sub_menu--show {
                display: block;
            }

            .child_sub_menu--hide {
                // display: none;
                display: none;
            }

            a {
                font-size: 11pt;
                font-weight: bold;
                text-transform: uppercase;
                color: gray;
                text-decoration: none;
                
            }
            .a_protect {
                visibility: hidden;
            }

            .active_sidebar--show {
                color: #CFCBE4;
            }

            .active_sidebar--hide {
                color: gray;
            }
        }
    }
}

.LeftSidebar_LeftSection--show {
    transform: translateX(0);
    margin-right: 100px;
}

.LeftSidebar_LeftSection--hide {
    transform: translateX(-$left-sidebar-width);
}