.button-table-delete {
    text-decoration: none;
    button{
        display: flex;
        justify-items: center;
        background-color: #B73E3E;
        padding: 6px 8px 6px 8px;
        border-radius: 4px;
        color: white;
        font-weight: bold;
    }
    :hover{
        background-color: #DD5353;
    }
}

.button-table-status {
    text-decoration: none;
    button{
        display: flex;
        justify-items: center;
        background-color: #5A6268;
        padding: 6px 9px 6px 9px;
        border-radius: 4px;
        color: white;
        font-weight: bold;
    }
    :hover{
        background-color: #4d5358;
    }
}

.button-table-edit {
    text-decoration: none;
    button{
        display: flex;
        justify-items: center;
        background-color: #6EF3D6;
        padding: 6px 15px 6px 15px;
        border-radius: 4px;
        color: white;
        font-weight: bold;
    }
    :hover{
        background-color: #C6FCE5;
    }
}